//
//
//
//
//
//
//
//
//
//
//
//
//
//
import cash from '@modules/cash/mixins/cash';
import CashWrapper from "./CashWrapper";
export default {
  name: 'ThankYou',
  mixins: [cash],
  components: {
    CashWrapper: CashWrapper
  },
  methods: {
    close: function close() {
      this.modalCommandClose();
    }
  }
};